<script setup lang="ts">
import LineUser01 from '@docue/docue-ui-v2/icons/LineUser01.vue'
import LineUsers01 from '@docue/docue-ui-v2/icons/LineUsers01.vue'
import LineGlobe01 from '@docue/docue-ui-v2/icons/LineGlobe01.vue'
import LineSettings01 from '@docue/docue-ui-v2/icons/LineSettings01.vue'
import LineFolder from '@docue/docue-ui-v2/icons/LineFolder.vue'
import LineCreditCard02 from '@docue/docue-ui-v2/icons/LineCreditCard02.vue'
import LineChevronLeft from '@docue/docue-ui-v2/icons/LineChevronLeft.vue'
import { useScroll } from '@vueuse/core'

const { user, workspace, isCurrentUserAdmin } = useAuth()
const { terms, privacyPolicy } = useLinks()

const route = useRoute()
const { t } = useI18n()

const main = ref<HTMLElement>()
const { y: mainScrollPosY } = useScroll(main)

const pageTitle = computed(() => ({
  '/2/settings/users': t('settings.users-title'),
  '/2/settings/documents': t('settings.documents-title'),
}[route.path]))
</script>

<template>
  <div class="flex bg-white lg:bg-gray-50">
    <aside
      class="hidden min-w-[265px] flex-col gap-2 bg-white px-2.5 py-4 drop-shadow lg:flex"
    >
      <NuxtLink
        to="/2/documents"
        class="group flex items-center gap-2 px-1 py-[3px]"
      >
        <DTIconButton
          variant="plain"
          as="div"
          class="group-hover:bg-gray-100"
        >
          <LineChevronLeft class="size-5 shrink-0 text-gray-600" />
        </DTIconButton>
        <span class="font-semibold">{{ $t('settings.title') }}</span>
      </NuxtLink>
      <div class="mt-2.5 flex flex-col gap-4.5">
        <DTNavList class="gap-2">
          <DTNavListHeading class="px-1 text-xs">
            {{ user?.attributes.email }}
          </DTNavListHeading>
          <DTNavItem to="/settings/profile">
            <LineUser01 class="size-4 shrink-0" />
            {{ $t('settings.profile-title') }}
          </DTNavItem>
          <DTNavItem to="/settings/language">
            <LineGlobe01 class="size-4 shrink-0" />
            {{ $t('settings.language-title') }}
          </DTNavItem>
        </DTNavList>
        <DTNavList
          v-if="isCurrentUserAdmin"
          class="gap-2"
        >
          <DTNavListHeading class="px-1 text-xs">
            {{ workspace?.attributes.displayName }}
          </DTNavListHeading>
          <DTNavItem to="/settings/account">
            <LineSettings01 class="size-4 shrink-0" />
            {{ $t('settings.workspace-title') }}
          </DTNavItem>
          <DTNavItem
            to="/2/settings/documents"
            :active="route.path === '/2/settings/documents'"
          >
            <LineFolder class="size-4 shrink-0" />
            {{ $t('settings.documents-title') }}
          </DTNavItem>
          <DTNavItem
            to="/2/settings/users"
            :active="route.path === '/2/settings/users'"
          >
            <LineUsers01 class="size-4 shrink-0" />
            {{ $t('settings.users.title') }}
          </DTNavItem>
          <DTNavItem to="/settings/order">
            <LineCreditCard02 class="size-4 shrink-0" />
            {{ $t('settings.order-title') }}
          </DTNavItem>
        </DTNavList>
      </div>
      <div class="mb-0 mt-auto flex flex-col gap-3 px-1 pb-4">
        <NuxtLink
          :to="privacyPolicy"
          class="text-xs text-gray-400"
        >
          {{ $t('privacy-policy') }}
        </NuxtLink>
        <NuxtLink
          :to="terms"
          class="text-xs text-gray-400"
        >
          {{ $t('terms-of-service') }}
        </NuxtLink>
      </div>
    </aside>
    <main
      ref="main"
      class="grow overflow-y-auto pb-28 md:pb-0 lg:p-10"
    >
      <header
        class="sticky top-0 z-10 h-16 bg-white px-2.5 pt-4 lg:hidden"
        :class="{ 'drop-shadow-md': mainScrollPosY > 30 }"
      >
        <NuxtLink
          to="/settings"
          class="group flex items-center gap-2 px-1 py-[3px]"
        >
          <DTIconButton
            variant="plain"
            as="div"
            class="group-hover:bg-gray-100"
          >
            <LineChevronLeft class="size-5 shrink-0 text-gray-600" />
          </DTIconButton>
          <span class="font-semibold">{{ pageTitle }}</span>
        </NuxtLink>
      </header>
      <div class="mx-auto max-w-3xl px-4 md:px-8 lg:px-0">
        <slot />
      </div>
    </main>
  </div>
</template>
